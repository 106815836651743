/* eslint-disable react/prop-types */
import { useStaticQuery, graphql } from "gatsby";
import { createContext, useEffect, useState } from "react";

import { YMInitializer } from "react-yandex-metrika/src";
import i18next from "../../i18n";
// eslint-disable-next-line import/no-cycle
import { useWindowSize } from "../../helpers";
import { TARGET_LANGUAGE } from "../../../config/i18n";

const LocaleContext = createContext({
  localePath: "",
});

const YM_ACCOUNTS = { ru: 98321419, en: 22871677 };

const Layout = ({
  children,
  pageContext: { locale, siteLanguage, localePath },
}) => {
  useEffect(() => {
    i18next.changeLanguage(siteLanguage);
  }, [siteLanguage]);

  const data = useStaticQuery(graphql`
    query getPromotion{
      promotion {
        reason
      }
    }
  `);

  const [isCookiesBanner, setCookiesBanner] = useState(true);

  const mobile = useWindowSize();
  const mobileOnly = useWindowSize(550);

  const { promotion } = data;

  let banner;
  let isSaleCompany;
  let isPromoBanner;

  switch (promotion.reason) {
    case "none":
      isSaleCompany = false;
      isPromoBanner = false;
      break;
    case "ChristmasSale":
      isSaleCompany = true;
      isPromoBanner = true;
      banner = "christmas";
      break;
    case "FlashSale":
      isSaleCompany = true;
      isPromoBanner = true;
      banner = "flashSale";
      break;
    case "CyberMonday":
      isSaleCompany = true;
      isPromoBanner = true;
      banner = "cyberMonday";
      break;
    case "BlackFriday":
      isSaleCompany = true;
      isPromoBanner = true;
      banner = "blackFriday";
      break;
    case "NewVersion":
      isSaleCompany = false;
      isPromoBanner = true;
      banner = "newVersion";
      break;
    default:
      isSaleCompany = false;
      isPromoBanner = false;
      break;
  }

  return (
    <>
      <LocaleContext.Provider
        value={{
          locale,
          siteLanguage,
          localePath,
          isMobile: mobile,
          isMobileOnly: mobileOnly,
          isSaleCompany,
          isPromoBanner,
          banner,
          isCookiesBanner,
          setCookiesBanner,
        }}
      >
        <>
          {children}
          <YMInitializer
            accounts={[YM_ACCOUNTS[TARGET_LANGUAGE]]}
            options={{
              webvisor: true,
              defer: true,
              clickmap: true,
            }}
          />
        </>
      </LocaleContext.Provider>
    </>
  );
};

export { Layout, LocaleContext };
